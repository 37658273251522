<template>
  <div>
    <v-snackbar v-model="snackbar" color="#3CB043" right :timeout="3000">
      <v-layout wrap>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="snackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs12 sm6>
            <v-img src="./../../assets/login.png">
              <v-layout wrap justify-center align-center fill-height pb-16>
                <v-flex xs12 pb-16 text-left>
                  <v-layout wrap justify-center>
                    <v-flex xs12 sm6>
                      <span
                        style="
                          font-family: poppinsbold;
                          color: white;
                          font-size: 30px;
                        "
                        >Your Education Journey Starts Here</span
                      >
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-center pt-6>
                    <v-flex xs12 sm6>
                      <span
                        style="
                          font-family: poppinsregular;
                          color: white;
                          font-size: 16px;
                        "
                        >Embrace a
                        future filled with endless possibilities as you join our
                        educational community</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-img>
          </v-flex>
          <v-flex xs12 sm6 align-self-center>
            <v-layout wrap justify-center>
              <v-flex xs12 sm9>
                <v-card outlined>
                  <v-form ref="form">
                  <v-layout wrap justify-center py-10>
                    <v-flex xs10>
                      <v-layout wrap>
                        <v-flex xs12>
                          <span
                            style="
                              font-family: poppinsregular;
                              color: #5a5a5a;
                              font-size: 16px;
                            "
                            >Welcome Back !</span
                          >
                        </v-flex>
                        <v-flex xs12>
                          <span
                            style="
                              font-family: poppinsbold;
                              color: #5a5a5a;
                              font-size: 20px;
                            "
                            >Login your account</span
                          >
                        </v-flex>
                        <v-flex xs12 py-5>
                          <span
                            style="
                              font-family: poppinsregular;
                              color: #000000;
                              font-size: 14px;
                            "
                            >Enter your details below</span
                          >
                        </v-flex>
                      </v-layout>

                      <v-layout wrap>
                        <!-- <v-flex xs12 text-left>
                          <span
                            style="
                              font-family: opensansregular;
                              font-size: 14px;
                            "
                            >Account type</span
                          >
                          <v-select outlined dense :hide-details="true" :items="items">
                          </v-select>
                        </v-flex> -->
                        <v-flex xs12 text-left py-2>
                          <span
                            style="
                              font-family: opensansregular;
                              font-size: 14px;
                            "
                            >Email/UserId <span style="font-size: 12px;">*Field is case sensitive</span></span
                          >
                          <v-text-field outlined dense v-model="email" :hide-details="true">
                          </v-text-field>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span
                            style="
                              font-family: opensansregular;
                              font-size: 14px;
                            "
                            >Password</span
                          >
                          <v-text-field outlined type="password" dense v-model="password" :hide-details="true">
                          </v-text-field>
                        </v-flex>
                        <v-flex xs12 text-right>
                          <router-link to="/forgotPassword">
                          <span
                            style="
                              font-family: opensansregular;
                              font-size: 14px;
                              color: #3cb043;
                            "
                            >Forgot Password</span
                          >
                        </router-link>
                        </v-flex>
                        <v-flex xs12 py-5>
                          <v-btn color="#3CB043" block @click="login()"
                            ><span
                              style="
                                font-family: poppinsregular;
                                color: #fafafa;
                                text-transform: none;
                              "
                            >
                              Login</span
                            ></v-btn
                          >
                        </v-flex>
                        <v-flex xs12>
                          <span
                            style="
                              font-family: poppinsbold;
                              font-size: 15px;
                              color: #000000;
                            "
                            >DON'T HAVE A STUDENT ACCOUNT YET ! 
                            <span> <router-link  style="color: #3cb043" :to="'/signUp'">&nbsp; SIGNUP</router-link></span></span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  </v-form>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import store from "../../store";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      items:["Institution","Student"],
      email:"",
      password:"",
      snackbar: false,
      msg: null,
    };
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        axios({
          method: "POST",
          url: "/user/login",
          data: {
            email: this.email,
            password: this.password,
            guestId: localStorage.getItem("userId")
          },
        }).then((response) => {
          if (response.data.status) {
            store.commit("loginUser", response.data.userData);
            store.commit("userData", response.data.userData);
            localStorage.setItem('userDataId', response.data.id)
            localStorage.setItem("token", response.data.token);
            localStorage.removeItem("userId");
            location.reload()
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }
        });
      }
    },
  }
};
</script>